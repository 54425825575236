import Toaster from "./toaster.vue";
import ToastItem from "./toast-item.vue";
import { emitter } from "~/libs/emitter";

interface ToastOptions {
  title: string;
  type: "error" | "success";
}

const emitToast = (options: ToastOptions) => {
  emitter.emit("respostaToast", options);
};

const toast = {
  success: (title: string) => {
    emitToast({ title, type: "success" });
  },
  error: (title: string) => {
    emitToast({ title, type: "error" });
  },
};

export { Toaster, ToastItem, toast };
export type { ToastOptions };
